/* eslint-disable @typescript-eslint/no-explicit-any */
import { StockLedgerEntry, Page, Warehouse } from "@/types";
import { api } from "@/util/axios";
import { Module } from "vuex";

type StockInventoryState = {
  salesLedgerPage: Page<StockLedgerEntry>;
  warehousePage: Page<Warehouse>;
  stockEntries: StockLedgerEntry[];
  warehouses: Warehouse[];
};

const saleLedger: Module<StockInventoryState, unknown> = {
  namespaced: true,
  state: () => ({
    salesLedgerPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    warehousePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    stockEntries: [],
    warehouses: [],
  }),
  mutations: {
    SET_STOCK_ENTRY_PAGE: (state, stockEntryPage) => {
      state.salesLedgerPage = stockEntryPage;
      state.stockEntries = stockEntryPage.docs;
    },
    REMOVE_STOCK_ENTRY: (state, stockEntryId) => {
      state.stockEntries = state.stockEntries.filter(
        (item) => item._id !== stockEntryId
      );
    },
  },
  getters: {
    // Stock Entry Getters
    salesLedgerPage: (state) => state.salesLedgerPage,
    getStockEntry: (state) => (stockEntryId: string) =>
      state.stockEntries.find((entry) => entry._id === stockEntryId),

    // Warehouse Getters
    warehousePage: (state) => state.warehousePage,
    getWarehouse: (state) => (warehouseId: string) =>
      state.warehouses.find((warehouse) => warehouse._id === warehouseId),
  },
  actions: {
    fetchStockEntries({ commit, dispatch }, params = "") {
      return api
        .get(`/v1/sales-ledger`, { params })
        .then((response) => {
          commit("SET_STOCK_ENTRY_PAGE", response.data.stockEntries);
          return response.data.stockEntries;
        })
        .catch((error) => {
          dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text:
                error.response?.data?.error?.message ||
                error.message ||
                "An error occurred",
            },
            { root: true }
          );
          throw error;
        });
    },
  },
};

export default saleLedger;
