var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-toolbar',{staticClass:"mt-6 flex-wrap-reverse",attrs:{"flat":"","dense":""}},[_c('EmployeeMiniSearch',{on:{"employee":_vm.filterByEmployee}}),_c('hr'),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredData,"options":_vm.options,"server-items-length":_vm.salesLedgerPage.total,"loading":_vm.loading},on:{"update:options":[function($event){_vm.options=$event},_vm.loadStockEntries]},scopedSlots:_vm._u([{key:"item.order",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.order.orderNo)+" ")]}},{key:"item.transactionType",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.transactionType === 'SALE' ? 'green--text' : 'red--text'},[_vm._v(" "+_vm._s(item.transactionType)+" ")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity)+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.order.client.fullName || "N/A")+" ")]}},{key:"item.orderedBy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.order.user.displayName || "N/A")+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt).toLocaleString())+" ")]}}])},[_vm._v(" "+_vm._s(_vm.productPage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }